import {
    HomeOutlined, FileTextOutlined, FileSearchOutlined, ProjectOutlined, PayCircleOutlined, WhatsAppOutlined,
    SettingOutlined, CoffeeOutlined, LikeOutlined
} from '@ant-design/icons';
import I18n from "../language/I18n";
import moment from "moment";

global.isDev = process.env.NODE_ENV === "development";
console.log("window.location", window.location)
console.log("window.location.host", window.location.host)
const protocol = window.location.protocol;
let serverIP = "afs.idelonghi.cn"; //德隆售后
// serverIP = "192.168.2.196"; //德隆售后香港
// let serverPORT = "8005";
let serverPORT = 80;
if (window.location.port === "" || window.location.protocol.indexOf("https:") !== -1) {
    serverPORT = "";
} else {
    serverPORT = ":" + serverPORT;
}
const IPArr = [
    "afs.idelonghi.cn", "110.42.222.148", "192.168.168.153:81",
    // "127.0.0.1"
]
if (IPArr.includes(window.location.host)) {
    serverIP = "afs.idelonghi.cn"; //德隆售后
    serverIP = window.location.host; //德隆售后
    serverPORT = "";
}
if (window.location.host == "192.168.168.153:81") {
    serverIP = "192.168.168.153"; //德隆售后
    if (window.location.port != '')
        serverPORT = ":81";
}
global.isLocalTest = false;
// 本地测试

if (window.location.host.includes("192.168.10.2") || window.location.host.includes("10.0.2.15")) {
    global.isLocalTest = true;
}
console.log("本地测试", global.isLocalTest)
// 本地测试环境配置
if (global.isDev || global.isLocalTest) {
    serverIP = "afs.idelonghi.cn";
    serverIP = "192.168.10.2";
    // serverIP = "192.168.10.5";
    // serverIP = "10.0.2.15";
}

global.serverIP = serverIP;
const ServerHost = `${protocol}//${serverIP}${serverPORT}`;
console.log("ServerHost", ServerHost)

// const PublicServerIP = ["afs.idelonghi.cn", "110.42.222.148", "192.168.168.153"]; // 生产环境
// let isPublic = false;
// if (PublicServerIP.includes(serverIP)) {
//     isPublic = true;
// }

global.isTestPublic = false;
// 测试链接
if (window.location.href.includes("afs.idelonghi.cn/test") || window.location.href.includes("192.168.10.2/test")) {
    global.isTestPublic = true;
}

console.log("测试链接", global.isTestPublic)

// 控制新功能开放
global.openNewFunc = (code) => {
    const now = new Date().getTime();
    if (code === "20240715") {
        const effectiveTime = new Date("2024-07-01 00:00:00").getTime();
        if (global.isDev) {
            if (now >= effectiveTime) {
                return true;
            }
        } else if (global.isTestPublic) {
            return true;
        }
    }
    return false;
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    title: "网络售后维护系统",
    version: "1.7.5",
    locale: "zh_CN",
    /**
     * @description api请求基础路径
     */
    autoLogin: { dev: true, pro: false, },
    baseUrl: {
        dev: `${ServerHost}/api/v1/`,
        pro: `${ServerHost}/api/v1/`,
    },
    templateUrl: {
        dev: `${ServerHost}/`,
        pro: `${ServerHost}/`,
    },
    baseImageUrl: {
        dev: `${ServerHost}/file/`,
        pro: `${ServerHost}/file/`,
    },
    baseFileUrl: {
        dev: `${ServerHost}/file/`,
        pro: `${ServerHost}/file/`,
    },
    menus: [
        { name: "首页", key: "/sys/home", icon: <HomeOutlined /> },
        { name: "维修单服务", key: "/sys/fixApp", icon: <FileTextOutlined /> },
        { name: "整机更换", key: "/sys/replace", icon: <FileTextOutlined /> },
        // { name: "维修单查询", key: "/sys/fixAppQuery", icon: <FileTextOutlined /> },
        { name: "零件信息查询", key: "/sys/partInfo", icon: <FileSearchOutlined /> },
        { name: "考核与统计", key: "/sys/assessment", icon: <ProjectOutlined /> },
        { name: "结账单服务", key: "/sys/checkout", icon: <PayCircleOutlined /> },
        { name: "订单服务", key: "/sys/order", icon: <FileTextOutlined />, orderUpload: true },
        { name: "自动咖啡机维护保养", key: "/sys/maintenance", icon: <CoffeeOutlined />, baoyang: true },
        { name: "维护保养查询", key: "/sys/coffeeAppQuery", icon: <CoffeeOutlined />, baoyang: true },
        { name: "保养回访查询", key: "/sys/followup", icon: <WhatsAppOutlined />, baoyang: true },
        { name: "400回访查询", key: "/sys/followup_400", icon: <WhatsAppOutlined />, baoyang: true },
        { name: "奖惩查询", key: "/sys/awards", icon: <LikeOutlined /> },
        { name: "系统日志", key: "/sys/audit", icon: <FileTextOutlined />, admin: true },
        { name: "设置", key: "/sys/setting", icon: <SettingOutlined />, admin: true },
    ],
    baoyangMenu: ['/sys/maintenance', '/sys/coffeeAppQuery', '/sys/followup'],
    defaultTheme: {
        rowLightColor: "rgb(238, 238, 238)",
        rowDarkColor: "rgb(223, 225, 234)",
        rowHighDarkColor: "rgb(171, 174, 191)",
        editBackColor: "rgb(208, 210, 222)", //"#D0D2DE"
    },
    MainContentHeight: "calc(100vh - 134px)",
    pagination: (total, page, pageSize, onPageChange) => {
        return {
            // style: { display: total > pageSize ? "flex" : "none", margin: 10 },
            style: { display: "flex", margin: 10 },
            pageSizeOptions: ["100", "200", "500", "1000", "2000"],
            total: total,
            pageSize: pageSize,
            current: page,
            // showTotal: (total, range) => `${I18n.t("显示第")}${range[0]}${I18n.t("到第")}${range[1]}${I18n.t("条记录，总共")}${total}${I18n.t("条记录")}`,
            showTotal: (total, range) => `${I18n.t("显示第{{:a}}到第{{:b}}条记录，总共{{:c}}条记录", { a: range[0], b: range[1], c: total })}`,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
        }
    },
    getDefaultRanges: function () {
        return {
            [`${I18n.t("今天")}`]: [moment(), moment()],
            [`${I18n.t("上月")}`]: [moment().add(-1, "months").startOf('month'), moment().add(-1, "months").endOf('month')],
            [`${I18n.t("本月")}`]: [moment().startOf('month'), moment().endOf('month')],
            [`${I18n.t("本年")}`]: [moment().startOf('year'), moment().endOf('year')],
        }
    },
}
